// Generated by Framer (21ec140)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["PSL2XY81Y", "i0OPhHDpi"];

const serializationHash = "framer-BO6mR"

const variantClassNames = {i0OPhHDpi: "framer-v-l0wb6l", PSL2XY81Y: "framer-v-1c54xi5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Closed: "PSL2XY81Y", Open: "i0OPhHDpi"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "PSL2XY81Y"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "PSL2XY81Y", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1c54xi5", className, classNames)} data-framer-name={"Closed"} layoutDependency={layoutDependency} layoutId={"PSL2XY81Y"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({i0OPhHDpi: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1pw0y4u"} data-framer-name={"Frame"} layoutDependency={layoutDependency} layoutId={"BQOMiiwhk"} style={{backgroundColor: "var(--token-ebfdfc6f-a0e8-414d-b30e-eeab99d6e438, rgb(158, 163, 191))", rotate: 0}} variants={{i0OPhHDpi: {rotate: -180}}}><SVG className={"framer-6ha95t"} data-framer-name={"Icon"} layout={"position"} layoutDependency={layoutDependency} layoutId={"dd991lg1Z"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 14\"><path d=\"M 3 5.5 L 7 9.5 L 11 5.5\" fill=\"transparent\" stroke-width=\"2\" stroke=\"#aaa\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>"} svgContentId={11713537759} withExternalLayout/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BO6mR.framer-a9qih4, .framer-BO6mR .framer-a9qih4 { display: block; }", ".framer-BO6mR.framer-1c54xi5 { height: 14px; overflow: visible; position: relative; width: 14px; }", ".framer-BO6mR .framer-1pw0y4u { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }", ".framer-BO6mR .framer-6ha95t { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 14
 * @framerIntrinsicWidth 14
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"i0OPhHDpi":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerVqSNE23Zg: React.ComponentType<Props> = withCSS(Component, css, "framer-BO6mR") as typeof Component;
export default FramerVqSNE23Zg;

FramerVqSNE23Zg.displayName = "Caret";

FramerVqSNE23Zg.defaultProps = {height: 14, width: 14};

addPropertyControls(FramerVqSNE23Zg, {variant: {options: ["PSL2XY81Y", "i0OPhHDpi"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerVqSNE23Zg, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})